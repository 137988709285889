import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { AppContext } from "../../../context/AppContext";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import "./dropdown.css";

//image
import NishkaLogo from "../assets/NishkaEra_Logo.png";
import SearchBar from "../../SearchBar";
import { getCategories } from "../../../services";

export default function Header() {
	const [isOpen, setIsOpen] = useState(false);

	const { user, dispatch } = useContext(AppContext);
	const [loginuser, setLoginuser] = useState(null);
	const history = useHistory();
	const pathname = window.location.pathname;

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("user");
		dispatch({ type: "LOGOUT" });
		history.push("/");
	};
	useEffect(() => {
		setLoginuser(user);
	}, [user]);

	const [categories, setCategories] = useState([]);

	useEffect(() => {
		getCategories().then((newCategories) => {
			setCategories(newCategories);
		});
	}, []);

	// const [products, setProducts] = useState(false);
	// const openProducts = (
	//   <div className="bg-white-500 flex flex-col -mt-7 text-nishblue-400 ml-20 py-3 px-2 items-center">
	//     <div className="py-2 px-2">Fixed Deposit</div>
	//     <div className="py-2 px-2">Personal Loan</div>
	//     <div className="py-2 px-2">Buisness Loans</div>
	//   </div>
	// );
	// const linkName = products ? "Products ×" : "Products +";

	return (
		<>
			<div className="bg-white h-full flex py-4 items-center px-5 gap-2 justify-between mb-8">
				<div className="hidden md:block w-1/2 md:w-1/5">
					<a style={{ textDecoration: "none" }} href={"/"}>
						<img className="w-full" src={NishkaLogo} />
					</a>
				</div>

				<div className="hidden md:block">
					<div className="flex md:gap-0 lg:gap-8 xl:gap-12 text-sm md:text-md lg:text-lg xl:text-xl font-semibold text-navgreen-700">
						<Link style={{ textDecoration: "none" }} to={"/"}>
							<div className="px-2 font-bold text-navgreen-700 cursor-pointer">
								Home
							</div>
						</Link>

						<div className="flex md:gap-0 lg:gap-8 xl:gap-12 text-sm md:text-md lg:text-lg xl:text-xl font-semibold text-navgreen-700">
							{categories.map((category, index) => (
								<a key={index} href={`/category/${category.slug}`}>
									<div className="px-2 font-bold text-navgreen-700 cursor-pointer">
										{category.name}
									</div>
								</a>
							))}
						</div>

						{/* <div className="group inline-block">
              <div className="outline-none focus:outline-none px-3 bg-white rounded-sm flex items-center min-w-32">
                <span className="pr-1 font-bold cursor-pointer flex-1">Products</span>
                <span>
                  <svg className="fill-current h-4 w-4 transform group-hover:-rotate-180
                    transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </div>
              <ul className="bg-navgreen-600 border -ml-4 pr-2 list-none z-10 rounded-b-xl text-center shadow-3xl transform scale-0 group-hover:scale-100 absolute 
                transition duration-150 ease-in-out origin-top min-w-32 ">
                <Link style={{ textDecoration: 'none' }} to={"/FixedDeposits"}><li className="rounded-sm px-1 no-underline text-white -ml-6 text-left font-normal py-1 ">Fixed Deposit</li></Link>
                <hr className="text-nishblue-100 p-0 m-0 -ml-6" />
                <Link style={{ textDecoration: 'none' }} to={"/PersonalLoan"}><li className="rounded-sm px-1 no-underline text-white -ml-6 text-left font-normal py-1 ">Personal Loan <span className="text-gray-200 font-light text-sm">Coming Up Soon</span></li></Link>
                <hr className="text-nishblue-100 p-0 m-0 -ml-6" />
                <li className="rounded-sm px-1 text-white disabled -ml-6 text-left font-normal py-1 ">Business Loan <span className="text-gray-200 font-light text-sm">Coming Up Soon</span></li>
                <hr className="text-nishblue-100 p-0 m-0 -ml-6" />
                <li className="rounded-sm relative -ml-6 px-3 py-1 ">
                  <div className="w-full text-left text-white font-normal items-center outline-none focus:outline-none">
                    <Link style={{ textDecoration: 'none' }} to={""}><span className="rounded-sm px-1 text-white disabled -ml-3 text-left font-normal py-1">More Products</span></Link>
                    <span className="mr-auto">
                      <svg className="fill-current h-4 w-4
                        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </span>
                  </div>
                  <ul className="bg-navgreen-100 border list-none -ml-9 shadow-3xl text-nishblue-900 font-normal absolute top-0 right-0 
                    transition duration-150 ease-in-out origin-top-left min-w-32 ">
                    <li className="rounded-sm relative -ml-6 px-3 py-1 ">
                      <div className="w-full text-left text-nishblue-900 font-normal flex items-center outline-none focus:outline-none">
                        <Link style={{ textDecoration: 'none' }} to={""}><span className="pr-1 text-nishblue-900 cursor-pointer flex-1">Insurance</span></Link>
                        <span className="mr-auto">
                          <svg className="fill-current h-4 w-4
                        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </span>
                      </div>
                      <ul className="bg-navgreen-100 w-max border list-none -ml-9 shadow-3xl text-nishblue-900 font-normal absolute top-0 right-0 
                    transition duration-150 ease-in-out origin-top-left min-w-32 ">
                        <li className="px-3 py-1 -ml-9 ">Term Insurance</li>
                        <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                        <Link style={{ textDecoration: 'none' }} to={""}><li className="-ml-9 text-nishblue-900 px-3 py-1 ">Health Insurance</li></Link>
                        <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                        <Link style={{ textDecoration: 'none' }} to={""}><li className="-ml-9 text-nishblue-900 px-3 py-1 ">Motor Insurance</li></Link>
                      </ul>
                    </li>
                    <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                    <Link style={{ textDecoration: 'none' }} to={""}><li className="-ml-9 text-nishblue-900 px-3 py-1 ">Bonds</li></Link>
                    <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                    <Link style={{ textDecoration: 'none' }} to={""}><li className="-ml-9 text-nishblue-900 px-3 py-1 ">NCD</li></Link>
                    <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                    <Link style={{ textDecoration: 'none' }} to={""}><li className="-ml-9 text-nishblue-900 px-3 py-1 ">PMS</li></Link>
                    <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                    <Link style={{ textDecoration: 'none' }} to={""}><li className="-ml-9 text-nishblue-900 px-3 py-1 ">Home Loans</li></Link>
                    <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                    <Link style={{ textDecoration: 'none' }} to={""}><li className="-ml-9 text-nishblue-900 px-3 py-1 ">Mutual Funds</li></Link>


                  </ul>
                </li>
              </ul>
            </div> */}

						{/* <div className="group inline-block">
              <div className="outline-none focus:outline-none px-3 bg-white rounded-sm flex items-center min-w-32">
                <span className="pr-1 font-bold cursor-pointer flex-1">Services</span>
                <span>
                  <svg className="fill-current h-4 w-4 transform group-hover:-rotate-180
                    transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </span>
              </div>
              <ul className="bg-navgreen-600 border -ml-20 list-none z-10 rounded-b-xl text-center shadow-3xl transform scale-0 group-hover:scale-100 absolute 
                transition duration-150 ease-in-out origin-top min-w-32  ">

                <li className="rounded-sm relative -ml-6 px-3 py-1 ">
                  <div className="w-full text-left text-white font-normal flex items-center outline-none focus:outline-none">
                    <Link style={{ textDecoration: 'none' }} to={"/Learn"}><span className="pr-1 text-white cursor-pointer flex-1">Financial Educational Services</span></Link>
                    <span className="mr-auto">
                      <svg className="fill-current h-4 w-4
                        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </span>
                  </div>
                  <ul className="bg-navgreen-100 border list-none -ml-9 shadow-3xl text-nishblue-900 font-normal absolute top-0 right-0 
                    transition duration-150 ease-in-out origin-top-left min-w-32 ">
                    <li className="px-3 py-1 -ml-9 ">The A to Z about Financial Products</li>
                    <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                    <Link style={{ textDecoration: 'none' }} to={"/Score"}><li className="-ml-9 text-nishblue-900 px-3 py-1 ">Financial Pulse</li></Link>
                    <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                    <Link style={{ textDecoration: 'none' }} to={"/Webinar"}><li className="-ml-9 text-nishblue-900 px-3 py-1 ">Webinar</li></Link>
                  </ul>
                </li>

                <hr className="text-nishblue-100 p-0 m-0 -ml-8" />

                <li className="rounded-sm relative px-3 -ml-6 py-1 ">
                  <div className="w-full text-left text-white font-normal flex items-center outline-none focus:outline-none">
                    <span className="pr-1 cursor-pointer flex-1">Financial Analysis Services</span>
                    <span className="mr-auto">
                      <svg className="fill-current h-4 w-4
                        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </span>
                  </div>
                  <ul className="bg-nishblue-100 border list-none shadow-3xl text-nishblue-900 font-normal absolute top-0 right-0 
                    transition duration-150 ease-in-out origin-top-left min-w-32 ">
                    <Link style={{ textDecoration: 'none' }} to={"/IPOWatch"}><li className=" text-nishblue-900 px-3 py-1 -ml-9 ">IPO Analysis</li></Link>
                    <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                    <li className="px-3 py-1 -ml-9 ">Financial Result Analysis <br /><span className="font-light text-sm">Coming Up Soon</span></li>
                    <hr className="text-nishblue-300 p-0 m-0 -ml-8" />
                    <li className="px-3 py-1 -ml-9 ">Daily Digest <br /><span className="font-light text-sm">Coming Up Soon</span></li>

                  </ul>
                </li>
                <hr className="text-nishblue-100 p-0 m-0 -ml-8" />
                <li className="rounded-sm relative px-3 -ml-6 py-1 ">
                  <div className="w-full text-white font-normal text-left flex items-center outline-none focus:outline-none">
                    <span className="pr-1 flex-1 cursor-pointer">Investment Advisory Services</span>
                    <span className="mr-auto">
                      <svg className="fill-current h-4 w-4
                        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </span>
                  </div>
                  <ul className="bg-nishblue-100 border list-none shadow-3xl text-nishblue-900 font-normal absolute top-0 right-0 
                    transition duration-150 ease-in-out origin-top-left min-w-32 ">
                    <li className="px-3 py-1 -ml-9 "><span className="font-light text-sm">Coming Up Soon</span></li>
                  </ul>
                </li>

              </ul>
            </div> */}

						{/* <Link style={{ textDecoration: 'none' }} to={"/Blogs"}><div className="px-2 cursor-pointer text-navgreen-700 font-bold">Blog</div></Link> */}
						{/* <Link style={{ textDecoration: 'none' }} to={"/OurStory"}><div className="px-2 text-navgreen-700 cursor-pointer font-bold">About Us</div></Link> */}
						<SearchBar />
					</div>
				</div>

				{/* <div className="hidden md:block rounded-sm text-sm md:text-md lg:text-lg xl:text-xl">
          <div className="text-navgreen-700 font-semibold inline-block rounded-lg shadow-0 border-none">
            {loginuser ? (
              <>
                <div className="py-1">
                  <div className="">
                    <h5 className="text-sm">Welcome {loginuser.name}</h5>
                  </div>
                  <div className="bg-nishblue-600 text-white py-1 rounded-xl text-sm cursor-pointer" onClick={handleLogout}>Logout</div>
                </div>
              </>
            ) : (
              <>
                <NavLink style={{ textDecoration: 'none' }}
                  className="bg-navgreen-700 text-white hover:text-white font-semibold px-5 py-1 rounded-lg shadow-0 border-none"
                  to="/login"
                >
                  Login
                </NavLink>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="md:hidden text-left ml-3 -mt-4">
        <Link style={{ textDecoration: 'none' }} to={"/"}><img
          className="w-30 h-10 lg:w-56 lg:h-12"
          src={NishkaLogo}
          alt="Logo"
        /></Link>
      </div>

      <div className="-mr-2 -mt-9 mb-4 pr-4 text-right items-center md:hidden">
        <div
          onClick={() => setIsOpen(!isOpen)}
          type="div"
          className="inline-flex items-center justify-center p-2 rounded-md hover:text-navgreen-700 hover:bg-white text-navgreen-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          {!isOpen ? <GiHamburgerMenu /> : <MdClose />}
        </div> */}
			</div>

			<Transition
				show={isOpen}
				enter="transition ease-out duration-100 transform"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="transition ease-in duration-75 transform"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
			>
				{(ref) => (
					<div className="md:hidden text-center " id="mobile-menu">
						<div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
							<a
								href="/"
								style={{ textDecoration: "none" }}
								className="hover:bg-white hover:text-navgreen-800 text-navgreen-700 block px-3 py-2 rounded-md text-base font-medium"
							>
								Home
							</a>
							<a
								href="/category/${category.slug}"
								style={{ textDecoration: "none" }}
								className="hover:bg-white hover:text-navgreen-800 text-navgreen-700 block px-3 py-2 rounded-md text-base font-medium"
							>
								{categories.map((category, index) => (
									<a key={index} href={`/category/${category.slug}`}>
										<div className="px-2 font-bold text-navgreen-700 cursor-pointer">
											{category.name}
										</div>
									</a>
								))}
							</a>

							{/* <div className="group inline-block ml-4">
                <div className="outline-none focus:outline-none text-navgreen-700 px-3 mx-auto bg-white text-base font-medium rounded-sm flex items-center w-max">
                  <span className="pr-1 flex-1">Products</span>
                  <span>
                    <svg className="fill-current h-4 w-4 transform group-hover:-rotate-180
                    transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </span>
                </div>
                <ul className="bg-navgreen-600 border ml-16 pr-2 list-none z-10 rounded-b-xl text-center shadow-3xl transform scale-0 group-hover:scale-100 absolute 
                transition duration-150 ease-in-out origin-top min-w-32">
                  <a style={{ textDecoration: 'none' }} href="/FixedDeposits"><li className="rounded-sm px-1 no-underline text-white -ml-6 text-left font-normal py-1">Fixed Deposit</li></a>
                  <a style={{ textDecoration: 'none' }} href="/PersonalLoan"><li className="rounded-sm px-1 no-underline text-white -ml-6 text-left font-normal py-1">Personal Loan<span className="font-light text-sm">Coming Up Soon</span></li></a>
                  <li className="rounded-sm px-1 no-underline text-white -ml-6 text-left font-normal py-1 ">Business Loan <span className="font-light text-sm">Coming Up Soon</span></li>
                  <li className="rounded-sm px-1 no-underline text-white -ml-6 text-left font-normal py-1">
                    <div className="w-full text-left text-white font-normal flex gap-0 items-center w-1/4 outline-none focus:outline-none">
                      <span className="pr-1 flex-1">More Products</span>
                      <span className="mr-auto">
                        <svg className="fill-current h-4 w-4
                        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </span>
                    </div>
                    <ul className="bg-navgreen-100 border list-none -ml-9 z-10 shadow-3xl text-nishblue-900 font-normal absolute top-0 right-0 
                    transition duration-150 ease-in-out origin-top-left min-w-32 ">
                      <li className="rounded-sm relative -ml-9 px-3 py-1 ">
                        <div className="w-full text-left text-nishblue-90o font-normal flex gap-0 items-center w-1/4 outline-none focus:outline-none">
                          <span className="pr-1 flex-1">Insurance</span>
                          <span className="mr-auto">
                            <svg className="fill-current h-4 w-4
                        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </span>
                        </div>
                        <ul className="bg-navgreen-100 border list-none -ml-9 z-10 shadow-3xl text-nishblue-900 font-normal absolute top-0 right-0 
                    transition duration-150 ease-in-out origin-top-left min-w-32 ">
                          <li className="px-3 py-1 -ml-9">Term Insurance</li>
                          <hr className="text-nishblue-300 p-0 m-0" />
                          <a style={{ textDecoration: 'none' }} href=""><li className="-ml-9 text-nishblue-900 px-3 py-1">Health Insurance</li></a>
                          <hr className="text-nishblue-300 p-0 m-0" />
                          <a style={{ textDecoration: 'none' }} href=""><li className="-ml-9 text-nishblue-900 px-3 py-1">Motor Insurance</li></a>
                        </ul>
                      </li>
                      <hr className="text-nishblue-100 p-0 m-0" />
                      <a style={{ textDecoration: 'none' }} href=""><li className="-ml-9 text-nishblue-900 px-3 py-1">Bonds</li></a>
                      <hr className="text-nishblue-100 p-0 m-0" />
                      <a style={{ textDecoration: 'none' }} href=""><li className="-ml-9 text-nishblue-900 px-3 py-1">NCD</li></a>
                      <hr className="text-nishblue-100 p-0 m-0" />
                      <a style={{ textDecoration: 'none' }} href=""><li className="-ml-9 text-nishblue-900 px-3 py-1">PMS</li></a>
                      <hr className="text-nishblue-100 p-0 m-0" />
                      <a style={{ textDecoration: 'none' }} href=""><li className="-ml-9 text-nishblue-900 px-3 py-1">Home Loans</li></a>
                      <hr className="text-nishblue-100 p-0 m-0" />
                      <a style={{ textDecoration: 'none' }} href=""><li className="-ml-9 text-nishblue-900 px-3 py-1">Mutual Funds</li></a>
                    </ul>
                  </li>
                </ul>



              </div><br /> */}

							{/* <div className="group inline-block mb-0 ml-4">
                <div className="outline-none hover:bg-white mx-auto hover:text-navgreen-800 text-navgreen-700 focus:outline-none px-3 mb-0 bg-white rounded-sm flex items-center -mt-4 w-max">
                  <span className="pr-1 font-medium flex-1">Services</span>
                  <span>
                    <svg className="fill-current h-4 w-4 transform group-hover:-rotate-180
                    transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </span>
                </div>
                <ul className="bg-navgreen-600 border list-none ml-10 z-10 rounded-b-xl text-center shadow-3xl transform scale-0 group-hover:scale-100 absolute 
                transition duration-150 ease-in-out origin-top min-w-32">


                  <li className="rounded-sm relative -ml-6 px-3 py-1 ">
                    <div className="w-full text-left text-white font-normal flex gap-0 items-center w-1/4 outline-none focus:outline-none">
                      <span className="pr-1 flex-1">Financial Educational Services</span>
                      <span className="mr-auto">
                        <svg className="fill-current h-4 w-4
                        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </span>
                    </div>
                    <ul className="bg-navgreen-100 border list-none -ml-9 z-10 shadow-3xl text-nishblue-900 font-normal absolute top-0 right-0 
                    transition duration-150 ease-in-out origin-top-left min-w-32 ">
                      <li className="px-3 py-1  -ml-9">The A to Z about Financial Products</li>
                      <hr className="text-nishblue-100 p-0 m-0" />
                      <a style={{ textDecoration: 'none' }} href="/Score"><li className="-ml-9 text-nishblue-900 px-3 py-1">Financial Pulse</li></a>
                      <hr className="text-nishblue-100 p-0 m-0" />
                      <a style={{ textDecoration: 'none' }} href="/Webinar"><li className="-ml-9 text-nishblue-900 px-3 py-1">Webinar</li></a>
                    </ul>
                  </li>


                  <li className="rounded-sm relative -ml-6 px-3 py-1 ">
                    <div className="w-full text-left text-white font-normal flex items-center outline-none focus:outline-none">
                      <span className="pr-1 flex-1">Financial Analysis Services</span>
                      <span className="mr-auto">
                        <svg className="fill-current h-4 w-4
                        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </span>
                    </div>
                    <ul className="bg-navgreen-100 border list-none -ml-9 z-10 shadow-3xl text-nishblue-900 font-normal absolute top-0 right-0 
                    transition duration-150 ease-in-out origin-top-left min-w-32">
                      <a style={{ textDecoration: 'none' }} href="/IPOWatch"><li className=" text-nishblue-900 px-3 py-1 -ml-9 ">IPO Analysis</li></a>
                      <hr className="text-nishblue-100 p-0 m-0" />
                      <li className="px-3 py-1 -ml-9 ">Financial Result Analysis <span className="font-light text-sm">Coming Up Soon</span></li>
                      <hr className="text-nishblue-100 p-0 m-0" />
                      <li className="px-3 py-1 -ml-9 ">Daily Digest <span className="font-light text-sm">Coming Up Soon</span></li>

                    </ul>
                  </li>

                  <li className="rounded-sm relative -ml-6 px-3 py-1 ">
                    <div className="w-full text-white font-normal text-left flex items-center outline-none focus:outline-none">
                      <span className="pr-1 flex-1">Investment Advisory Services</span>
                      <span className="mr-auto">
                        <svg className="fill-current h-4 w-4
                        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </span>
                    </div>
                    <ul className="bg-nishblue-100 border list-none shadow-3xl text-nishblue-900 font-normal absolute top-0 right-0 
                    transition duration-150 ease-in-out origin-top-left min-w-32 ">
                      <li className="px-3 py-1 -ml-9 "><span className="font-light text-sm">Coming Up Soon</span></li>
                    </ul>
                  </li>

                </ul>
              </div> */}

							{/* <a
                href="/Blogs"
                className="hover:bg-white hover:text-navgreen-800 text-navgreen-700 block px-3 py-2 rounded-md text-base font-medium"
              >
                Blog
              </a> */}

							{/* <a
                style={{ textDecoration: 'none' }}
                href="/OurStory"
                className="hover:bg-white hover:text-navgreen-800 text-navgreen-700 block px-3 py-2 rounded-md text-base font-medium"
              >
                About Us
              </a> */}

							{/* <div className="bg-navgreen-700 text-white font-semibold px-5 py-1 rounded-lg shadow-0 border-none hover:text-white">
                {loginuser ? (
                  <>
                    <div className="py-1">
                      <div className="">
                        <h6>Welcome {loginuser.name}</h6>
                      </div>
                      <div className="bg-nishblue-600 cursor-pointer" onClick={handleLogout}>Logout</div>
                    </div>
                  </>
                ) : (
                  <>
                    <NavLink
                      className="bg-navgreen-700 text-white font-semibold px-5 py-1 rounded-lg shadow-0 border-none hover:text-white"
                      to="/login"
                    >
                      Login
                    </NavLink>
                  </>
                )}
              </div> */}
						</div>
					</div>
				)}
			</Transition>
		</>
	);
}
