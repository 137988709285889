import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { getPosts } from "./services";
import { FeaturedPosts, PostCard, Header, PostDetails, CategoryPost, RecentPostWidget, Categories, Footer } from "./components";

function App() {

	const [posts, setPosts] = useState([]);

	useEffect(() => {
		getPosts().then((result) => {
			setPosts(result);
		});
	}, []);

	return (
		<div className="App">
			<BrowserRouter>
			{/* <Router> */}
				<Header />
				<Switch>
					{/* need to fix routing (url changes but page not loading automatically) */}
					<Route   path="/post/:postId" component={PostDetails} />
					<Route   path="/category/:categoryId" component={CategoryPost} />
					<Route exact  path="/" >
						<div className="container mx-auto px-10 mb-8">
						{/* <SearchBar /> */}
							<FeaturedPosts />
							<div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
								<div className="lg:col-span-8 col-span-1">
									
									{posts.map((post, index) => (
										<PostCard key={index} post={post.node} />
									))}
								</div>
								<div className="lg:col-span-4 col-span-1">
									<div className="lg:sticky relative top-8">
										<RecentPostWidget />
										<Categories />
									</div>
								</div>
							</div>
						</div>
					</Route>
				</Switch>
				<Footer />
			{/* </Router> */}
			</BrowserRouter>
		</div>
	);
}

export default App;
