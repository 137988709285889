import React, { useState } from 'react'
import { searchPosts } from '../services';

const getFilteredItems = (query, items) => {
    if(!query){
        return items;
    }
    return items.filter(post => post.name.includes(query))
}

function SearchBar() {

    // const [query, setQuery] = useState("");

    // const {posts} = searchPosts;
    // const {items} = posts;

    // const filteredItems = getFilteredItems(query, items);

  return (

    <div className="flex items-center justify-center ml-0.5 ">
      <div className="flex border-2 rounded-lg ">
          <input type="text" className="px-4 py-2 border-0 outline-none w-96 rounded-lg font-montserrat font-semibold placeholder-navgreen-700 text-gray-700 focus:ring-2 focus:ring-gray-200 bg-gray-200 shadow-lg" placeholder="Search" />
          <button className="flex items-center justify-center px-6 ml-2 border-0 rounded-lg cursor-pointer shadow-lg">
              <svg className="w-6 h-6 text-gray-600 fill-navgreen-700" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24">
                  <path
                      d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
              </svg>
          </button>
      </div>
    </div>
  )
}

export default SearchBar;

{/* <ul>
{filteredItems.map((value) => (
<h2 key={value.name}>{value.name}</h2>
))} 
 </ul>  */}