import React, { useState } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { IoIosMail } from "react-icons/io";
import {
  RiWhatsappFill,
  RiYoutubeFill,
  RiLinkedinBoxFill,
} from "react-icons/ri";
import { SiFacebook } from "react-icons/si";
import { BiRadioCircleMarked } from "react-icons/bi";
import { TiSocialInstagram } from "react-icons/ti";
import { AiFillTwitterCircle, AiOutlineCopyrightCircle } from "react-icons/ai";
import Logo from "../assets/nishka_footer.png";

// import { Button, Alert, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import twitter from '../assets/twitter.png';
import linkedin from '../assets/linkedin.png';
import instagram from '../assets/instagram.png';
import facebook from '../assets/facebook.png';
import youtube from '../assets/youtube.png';


// import dotenv from "dotenv";
import axios from "axios";
import "./Footer.css";

// dotenv.config();
export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      show: false,
      message: "",
      err: false,
      showUnsubscribe: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    const { show, message, err, showUnsubscribe, ...rest } = this.state;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_APIURL}/api/newsletter`,
        rest
      );
      this.setState({
        email: "",
        show: true,
        err: false,
        message: "Thankyou for subscribing to our newsletter.",
      });
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          this.setState({
            showUnsubscribe: true,
          });
        }
        error.response &&
          this.setState({
            show: true,
            err: true,
            message: error.response.data.message,
          });
      }
    }
  };
  closePopup = () => {
    this.setState({
      show: false,
      showUnsubscribe: false,
    });
  };
  onUpdate = () => {
    const { email } = this.state;
    console.log(email);
    axios
      .post(`${process.env.REACT_APP_APIURL}/api/newsletter/unsubscribe`, {
        email: email,
      })
      .then((res) => res.data)
      .then((data) =>
        this.setState({
          message: "Your email has been unsubscribed successfully!",
          showUnsubscribe: false,
        })
      );
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  render() {

    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <div className="flex flex-col md:flex-row bg-navgreen-700 text-white items-center p-3 pt-6">
            <div className="md:w-2/5 p-2">
              <div className="text-xl lg:text-2xl xl:text-3xl font-semibold">
                SUBSCRIBE TO OUR REPORTS
              </div>
              <div className="pt-3 lg:text-lg text-nishyellow-400 font-bold">
                {" "}
                Learn <span className="text-white">•</span> Invest <span className="text-white">•</span> Grow <span></span>{" "}
              </div>
            </div>
            <div className="md:w-2/5 p-2 flex flex-col items-center text-white">
              <div className="lg:w-3/4 md:w-4/5">
                <input
                  required
                  onChange={this.handleChange}
                  value={this.state.email}
                  name="email"
                  type="email"
                  placeholder="Your Email Address"
                  className="text-white w-full shadow-4xl outline-none pl-2 p-1.5 drop-shadow-md m-2 bg-navgreen-900 opacity-50 rounded-xl"
                />
              </div>
              {/* <div className="flex lg:text-lg gap-1.5 pt-3 md:pt-0 text-xs lg:gap-4 items-center ">
              <div>
                <span className="md:pr-1.5">
                  <BiRadioCircleMarked />
                </span>
                Daily Digest
              </div>
              <div>
                <span className="md:pr-1.5">
                  <BiRadioCircleMarked />
                </span>
                IPO Watch
              </div>
              <div>
                <span className="md:pr-1.5">
                  <BiRadioCircleMarked />
                </span>
                Financial Reports
              </div>
            </div> */}
            </div>
            <div className="md:w-1/5 lg:w-1/6 xl:-ml-20 mt-2 md:mt-0">
              <button
                type="submit"
                className="shadow-xl border-none text-lg text-white rounded-3xl px-8 font-semibold py-1 bg-nishyellow-400">
                Subscribe
              </button>
            </div>
          </div>
        </form>

        {/* <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.show}
          onHide={this.closePopup}
        >
          <Modal.Header className="bg-navgreen-600" closeButton></Modal.Header>
          <Modal.Body className="bg-navgreen-700 text-white">
            <Alert>
              {this.state.showUnsubscribe ? (
                <>
                  <h4>This email address is already subscribed.</h4>
                  <p>
                    To unsubscribe, click on <b>Unsubscribe</b> button below or{" "}
                    <b>Cancel</b> button to keep subscribed.
                  </p>
                  <Button
                    variant={this.state.err ? "danger" : "success"}
                    onClick={this.onUpdate}
                  >
                    Unsubscribe
                  </Button>
                </>
              ) : (
                <h4>{this.state.message}</h4>
              )}
            </Alert>
          </Modal.Body>
          <Modal.Footer className="bg-navgreen-600 text-center">
            <Button className="bg-white hover:bg-nishblue-400 mx-auto w-2/4" onClick={this.closePopup} variant="outline-primary">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal> */}

        <div className="w-full bg-navgreen-800 text-white pt-4 -pb-2 md:text-left md:pl-5 pb-3 md:pb-0">
          <img style={{ height: "70px" }} src={Logo} className="w-72 lg:w-71 md:w-48 md:h-14" />
        </div>

        <div className="items-center flex flex-col md:flex-row bg-navgreen-800 text-white xl:-mt-0 pb-10 lg:-mt-20  xl:-mt-2">
          <div className="flex flex-col md:w-1/5 items-center xl:-ml-8 lg:ml-2 md:-mt-24 lg:-mt-5 xl:-mt-24">
            <div className="text-footer-100 font-bold md:-mt-10 lg:-mt-0 text-lg lg:text-2xl">
              Company
            </div>
            <div className="flex flex-col text-sm lg:text-lg md:text-left">
              <p className="mt-6"><Link className="text-white hover:text-white" to="/OurStory">Our Story </Link></p>
              <p className="-mt-3"><Link className="text-white hover:text-white" to="/Careers">Careers </Link></p>
              <p className="-mt-3"><Link className="text-white hover:text-white" to="/Disclaimer">Disclaimer </Link></p>
              <p className="-mt-3"><Link className="text-white hover:text-white" to="/PrivacyPolicy">Privacy Policy </Link></p>
              <p className="-mt-3"><Link className="text-white hover:text-white" to="#">Terms Of Use </Link></p>
            </div>
          </div>
          <div className="flex flex-col md:-mt-16 md:w-1/4 lg:w-1/5 xl:w-1/4  xl:mt-8 lg:mt-20 items-center lg:ml-4 xl:-ml-10 xl:mr-16">
            <div className="text-footer-100 font-bold text-lg lg:text-2xl">
              Products
            </div>
            <div className="flex flex-row gap-3 lg:gap-8 xl:gap-12 lg:text-lg">
              <div className="flex flex-col text-sm md:text-left lg:text-lg">
                <p className="mt-6"><Link className="text-white hover:text-white" to="/FixedDeposits">Fixed Deposit </Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="/PersonalLoan">Personal Loan </Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="">Business Loan</Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="/Insurance">Insurance</Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="">Bonds</Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="">NCD</Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="">PMS</Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="">Home Loans</Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="/MutualFunds">Mutual Funds</Link></p>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:-mt-36 lg:-mt-0 md:w-1/4 lg:w-2/5 xl:w-1/4 md:ml-2 lg:-mt-20 xl:-mt-40 items-center xl:-ml-10 xl:mr-16">
            <div className="text-footer-100 font-bold text-lg lg:text-2xl  lg:-ml-4 xl:-ml-0">
              Services
            </div>
            <div className="flex flex-row gap-3 lg:gap-5 xl:gap-12 lg:text-lg">
              <div className="flex flex-col text-sm md:text-left lg:text-lg">
                <p className="mt-6"><Link className="text-white hover:text-white" to="/Learn">Learn </Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="">Daily Digest </Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="/IPOWatch">IPO Watch </Link></p>
              </div>
              <div className="flex flex-col text-sm md:text-left lg:text-lg">
                <p className="mt-6"><Link className="text-white hover:text-white" to="/Score">Financial Pulse</Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="/Blogs">Blogs </Link></p>
                <p className="-mt-3"><Link className="text-white hover:text-white" to="/WomenSpecialHome">Women Special</Link></p>
              </div>
            </div>
          </div>
          <div className="flex flex-col xl:pt-0 pt-6 md:pt-6 xl:-mt-16 md:mt-2 lg:pt-20 md:w-1/3 items-center md:mr-6">
            <div className="text-footer-100 font-bold text-lg lg:text-2xl -mt-2 md:-mt-8 ">
              Our Offices
            </div>
            <div className="flex flex-row gap-2 lg:gap-5 ml-5 lg:ml-8">
              <div className=" w-1/2 flex flex-col text-left ml-5">
                <p className="font-semibold mt-5 lg:text-lg">
                  Registered Office
                </p>
                <p className="text-sm -mt-2 flex">
                  <span className="mr-3 -ml-7 md:hidden lg:block">
                    <HiLocationMarker />
                  </span>
                  <a target="_blank" className="text-white" style={{ textDecoration: "none" }} href="https://goo.gl/maps/CFiyQnsDRXCQWgQc8">Flat no. 2, <br />A-wing, <br />Shyamsmruti CHS,<br />Nerul, Sec-19,<br />Navi Mumbai,
                    Maharashtra-400706</a>
                </p>
              </div>
              <div className="w-1/2 flex flex-col text-left">
                <p className="font-semibold mt-5 lg:text-lg">
                  Gurugram Office
                </p>
                <p className="text-sm  -mt-2 flex">
                  <span className="mr-3 -ml-7 md:hidden lg:block">
                    <HiLocationMarker />
                  </span>
                  <a target="_blank" className="text-white" style={{ textDecoration: "none" }} href="https://goo.gl/maps/JQr8SdsnNnCk6jLw6">Unit 125, <br />1st Floor,<br />Vipul Trade Center, Sohna Road,<br />Gurugram,<br />
                    Haryana-122018</a>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:w-1/4 lg:-mt-4 xl:-mt-24 md:-mt-28 items-center mr-2">
            <div>
              <div className="text-footer-100 font-bold text-lg lg:text-2xl">
                Contact Us
              </div>
              <div className="md:text-left mt-5">
                <p className="text-sm">
                  <span className="text-sm lg:mr-3 lg:text-lg">
                    <IoIosMail />
                  </span>
                  <a style={{ textDecoration: 'none' }} className="text-white" href="mailto:contact@nishkaera.com">contact@nishkaera.com</a>
                </p>
                <p className="-mt-3 text-sm">
                  <span className="text-sm lg:mr-3 lg:text-lg">
                    <RiWhatsappFill />
                  </span>
                  <a style={{ textDecoration: 'none' }} className="text-white" href="https://wa.me/+919320174124">+91 9320174124</a>
                </p>
              </div>
            </div>
            <div>
              <div className="mt-3 mb-6 text-footer-100 font-bold text-lg lg:text-2xl">
                Follow Us
              </div>
              <div className="flex flex-row gap-4 md:gap-2 lg:gap-6">
                <a href="https://www.facebook.com/nishkaera/" className="w-7 h-7 hover:text-white text-white">
                  <span className="w-14 h-14"><img className="w-full h-full" src={facebook} /> </span>
                </a>
                <a href="https://www.youtube.com/channel/UCboiaf0to-PrBpvPFXlqimQ" className="w-7 hover:text-white text-white h-7">
                  <span className="w-14 h-14"><img className="w-full h-full" src={youtube} /> </span>
                </a>
                <a href="https://www.instagram.com/nishkaera/" className="w-7 text-white hover:text-white h-7">
                  <span className="w-14 h-14"><img className="w-full h-full" src={instagram} /> </span>
                </a>
                <a href="https://www.linkedin.com/company/nishkaera/" className="w-7 text-white hover:text-white h-7">
                  <span className="w-14 h-14"><img className="w-full h-full" src={linkedin} /> </span>
                </a>
                <a href="https://www.twitter.com/nishkaera" className="w-7 text-white hover:text-white h-7">
                  <img className="w-full h-full" src={twitter} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex bg-navgreen-800 gap-2 text-white md:-mt-6 justify-center">
          <span className="h-6 w-6">
            <AiOutlineCopyrightCircle className="h-full w-full" />
          </span>
          <p>2022 All Rights Reserved</p>
        </div>
      </>
    );
  };
}