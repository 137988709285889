import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCategories } from "../../services";

const Categories = () => {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		getCategories().then((newCategories) => {
			setCategories(newCategories);
		});
	}, []);

	return (
		<div className="bg-nishblue-500 shadow-lg rounded-lg p-8 pt-3 pb-3 mb-8">
			<h3 className="text-xl text-white font-semibold border-solid border-t-0 border-l-0 border-r-0 border-b-2 mb-2 pb-4">
				Categories
			</h3>

			{categories.map((category, index) => (
				<div key={index}>
					<a href={`/category/${category.slug}`}>
						<span
							className={`cursor-pointer block ${
								index === categories.length - 1 ? "border-b-0" : "border-b"
							} pt-3 mt-3 text-white mb-2`}
						>
							{category.name}
						</span>
					</a>
					{/* <hr /> */}
				</div>
			))}
		</div>
	);
};

export default Categories;
