import React, { useState, useEffect } from "react";
import moment from "moment";
import { getSimilarPosts, getRecentPosts } from "../../services";
import "../../styles/globals.scss";
import { useRef } from "react";

const RecentPostWidget = ({ categories, slug }) => {
	const [relatedPosts, setRelatedPosts] = useState([]);
	const [count, setCount] = useState(0);
	const num = useRef(0);
	var i = 0;

	useEffect(() => {
		const interval = setInterval(() => {
			setCount((prevCount) => prevCount + 1);
			if (slug) {
				getSimilarPosts(categories, slug).then((result) => {
					setRelatedPosts(result);
				});
			} else {
				getRecentPosts().then((result) => {
					setRelatedPosts(result);
					if (i < result.length) {
						num.current = i;
						i++;
					}
					if (i == result.length || i > result.length) {
						i = 0;
					}
				});
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [slug]);

	// useEffect(() => {
	// 	if (slug) {
	// 		getSimilarPosts(categories, slug).then((result) => {
	// 			setRelatedPosts(result);
	// 		});
	// 	} else {
	// 		getRecentPosts().then((result) => {
	// 			setRelatedPosts(result);
	// 			if(result.length>i){
	// 					num=i;
	// 					return num;

	// 			}
	// 			i++;
	// 		});
	// 	}
	// }, [slug]);
	return (
		<>
			<div className="bg-nishblue-500 shadow-lg rounded-lg p-8 pb-3 pt-2 mb-8">
				<h3 className="text-xl text-white font-semibold border-solid border-t-0 border-l-0 border-r-0 border-b-2 mb-2 pb-4">
					{slug ? "Related Posts" : "Recent Posts"}
				</h3>
				<div className="bg-nishblue-500 scrollbar-hide overflow-y-auto h-80">
					<div className="con">
						<div className="scrolling pt-4 mt-4">
							{relatedPosts.map((post, index) => (
								<a href={`/post/${post.slug}`} className=" text-md" key={index}>
									{/* <div
								key={index}
								className={`flex  items-center w-full mb-4 ${
									num.current === index
										? "border-solid border-2 border-white rounded-md"
										: null
								}`}
							> */}
									<div
										key={index}
										className="flex items-center  w-full mb-4 hover:border-solid border-2 border-white rounded-md"
									>
										<div className="w-16 flex-none">
											<img
												//	loader={grpahCMSImageLoader}
												alt={post.title}
												height="60px"
												width="60px"
												// unoptimized
												className="align-middle rounded-full"
												src={`${post.featuredImage.url}`}
											/>
										</div>
										<div className="flex-grow ml-4">
											<p className="text-white font-xs font-semibold">
												{moment(post.createdAt).format("MMM DD, YYYY HH:MM")}
											</p>
											<p className="text-md text-white">{post.title}</p>
										</div>
									</div>
								</a>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RecentPostWidget;
