import React, { Component } from "react";
import { Categories, PostCard, Loader } from "../..";
import { getCategoryPost } from "../../../services";

class CategoryPost extends Component {
    state = { posts:{} , isLoading: true};
    componentDidMount(){
        this.getCategoryPostData();
    }

    getCategoryPostData = async() => {
        const { match } = this.props;
        const { params } = match;
        const { categoryId } = params;
        const data = await getCategoryPost(categoryId);
        this.setState({ posts: data, isLoading: false });
        // console.log(data);
    };

    render(){
        const { posts, isLoading } = this.state;
        return (
            <>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="container mx-auto px-10 mb-8">
                        <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
                            <div className="col-span-1 lg:col-span-8">
                            {posts.map((post, index) => (
                                <PostCard key={index} post={post.node} />
                            ))}
                            </div>
                            <div className="col-span-1 lg:col-span-4">
                            <div className="relative lg:sticky top-8">
                                <Categories />
                            </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default CategoryPost;
