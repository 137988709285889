import React from "react";

import moment from "moment";
import { Link } from "react-router-dom";
import LikeButton from '../LikeButton'

import { grpahCMSImageLoader } from "../../util";

const PostCard = ({ post }) => (
	<div className="bg-nishblue-500 shadow-lg rounded-lg p-0 lg:p-8 pb-12 mb-8">
		<div className="relative overflow-hidden shadow-md pb-80 mb-6">
			<img
				src={post.featuredImage.url}
				alt={post.title}
				className="object-top absolute h-80 w-full object-cover  shadow-lg rounded-t-lg lg:rounded-lg"
			/>
		</div>

		<h1 className="transition duration-700 text-center mb-8 cursor-pointer text-white hover:text-nishblue-800  text-3xl font-semibold">
			<Link to={`/post/${post.slug}`}>{post.title}</Link>
		</h1>
		<div className="block lg:flex text-center items-center justify-center mb-8 w-full">
			<div className="flex items-center justify-center mb-4 lg:mb-0 w-full lg:w-auto mr-8 items-center">
				<img
					unoptimized
					loader={grpahCMSImageLoader}
					alt={post.author.name}
					height="30px"
					width="30px"
					className="align-middle rounded-full"
					src={post.author.photo.url}
				/>
				<p className="inline align-middle text-white ml-2 font-medium text-lg">
					{post.author.name}
				</p>
			</div>
			<div className="flex items-center justify-center font-medium text-white">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-6 w-6 inline mr-2 text-nishyellow-400"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
					/>
				</svg>
				<span className="align-middle text-white">
					{moment(post.createdAt).format("MMM DD, YYYY")}
				</span>
			</div>
			<LikeButton />
		</div>
		<p className="text-center text-lg text-white font-normal px-4 lg:px-20 mb-8">
			{post.excerpt}
		</p>
		<div className="text-center">
		{/* <div className="block lg:flex text-center items-center justify-center mb-8 w-full"> */}
			<Link to={`/post/${post.slug}`}>
				<span className="transition duration-500 ease transform hover:-translate-y-1 inline-block bg-nishyellow-400 text-lg font-semibold rounded-full text-white px-8 py-3 cursor-pointer">
					Continue Reading
				</span>
			</Link>
			{/* <LikeButton post={{ id, likes, likeCount }}></LikeButton> */}
		</div>
		<div>
		</div>
	</div>
);

export default PostCard;
